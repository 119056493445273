import { FC, useMemo, useState } from 'react';
import { useDowloadGraphLanguage, useGetGraphLocales } from './useTranslations';
import type { Study, Locale } from 'src/redux/graph';
import { useSelector } from 'react-redux';
import { Button } from '@evidation/ui';
import Loader from 'src/components/Loader';
import AdvancedTable, {
  StyledTable as DefaultStyledTable,
} from '@evidation/advanced-table';
import styled from 'styled-components';
import TableDropdown from 'src/features/shared/TableDropdown';
import DeleteLanguageModal from './DeleteLanguageModal';
import UploadLanguagesButton from './UploadLanguagesButton';

const StyledTable = styled(DefaultStyledTable)`
  .record-counter {
    display: none;
  }
  .table-container {
    overflow-x: scroll;

    table {
      width: 100%;
      th {
        padding: 0.5em 0.5em 0;
      }
      tbody {
        tr {
          td,
          td p {
            text-align: center;
          }
        }
      }
    }
    margin-bottom: 40px;
  }
`;

const ImportActions = styled.p`
  display: flex;
  gap: 1rem;
`;

const columns = [
  {
    name: 'language',
    label: 'Language',
    min_width: 100,
  },
  {
    name: 'name',
    label: 'Name',
    min_width: 80,
  },
  {
    name: 'percent_translated',
    label: 'Translation Progress',
  },
  {
    name: 'export',
    label: 'Export Translation Strings',
  },
  {
    name: 'import',
    label: 'Import Translation Strings',
  },
];

const LanguageCell = (props) => {
  const languages: Locale = useSelector(
    (state: Locale) => state.locale.languages,
  );
  if (!languages) return null;
  const language = languages[props.rowData.name];
  return <>{language?.label}</>;
};

const PercentageTranslatedCell = (props) => {
  const progress = props.rowData.percent_translated ?? '0';
  return <>{progress * 100}%</>;
};

const ExportTranslationCell = (props) => {
  const graph: Study = useSelector((state: Study) => state.graph);
  const { mutate: onDownloadGraphLanguage } = useDowloadGraphLanguage();

  const handleOnClick = () => {
    onDownloadGraphLanguage({
      graphId: graph.id,
      locale: props.rowData.name,
    });
  };

  return (
    <Button
      variant="secondary"
      style={{ width: '100%' }}
      data-testid="i18n-btn"
      onClick={handleOnClick}
    >
      Export
    </Button>
  );
};

const ImportTranslationCell = (props) => {
  const graph: Study = useSelector((state: Study) => state.graph);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <ImportActions>
        <UploadLanguagesButton
          graphId={graph.id}
          locale={props.rowData.name}
          id={`upload-lang-${props.rowData.name}`}
        />
        <TableDropdown>
          <button onClick={() => setShowModal(true)}>Delete Language</button>
        </TableDropdown>
      </ImportActions>
      {showModal && (
        <DeleteLanguageModal
          language={props.rowData.name}
          graphId={graph.id}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

const GraphLanguageTable: FC = () => {
  const graph: Study = useSelector((state: Study) => state.graph);
  const { data: locales, isLoading } = useGetGraphLocales(graph.id);

  const customCells = useMemo(() => {
    return {
      language: LanguageCell,
      percent_translated: PercentageTranslatedCell,
      export: ExportTranslationCell,
      import: ImportTranslationCell,
    };
  }, []);

  return (
    <Loader show={isLoading}>
      <section>
        {locales && (
          <StyledTable>
            <AdvancedTable
              data={{ columns, data: locales }}
              sort={false}
              search={false}
              customCells={customCells}
              rowKey="name"
            />
          </StyledTable>
        )}
      </section>
    </Loader>
  );
};

export default GraphLanguageTable;
